<template>
  <div class="panel">
    <div class="dataSearchDelayTips">
      {{ $t("tips.dataSearchDelay") }}
    </div>
    <div class="panel-title">
      {{ $t("dashboard") }}
    </div>



    <el-row :gutter="20">
      <el-col :lg="16" :md="24" :xs="24" :sm="24">
        <div class="panel-sub-title">
          <div class="title">
            {{ $t("dashboardSubtitle") }}
          </div>

          <el-select class="cycle-select" v-model="chartsCycle" v-on:change="getChartsData(true)" :disabled="loading">
            <el-option v-for="item in cycleTypeList" :value="item.value" :label="item.name" :key="item.value">
            </el-option>
          </el-select>
        </div>

        <el-card class="panel-card" v-loading="loading">
          <g2-plot type="DualAxes" :option="chartOptions" v-if="!loading"></g2-plot>
        </el-card>
      </el-col>

      <el-col :lg="8" :md="24" :xs="24" :sm="24">
        <div class="panel-sub-title">
          <div class="title">
            {{ $t("otherNotification") }}
          </div>

          <router-link class="more-message" :to="{ path: 'message', query: { activeIndex: 'other' } }">
            {{ $t("more") }}
          </router-link>
        </div>

        <el-card class="panel-card" v-loading="messageLoading">
          <nodata v-if="messageData.length === 0" :imgW="175" :imgH="95" />
          <div v-else v-for="item in messageData" :key="item.messageId" class="other-notice-body"
            v-on:click="goNoticeDetails(item)">
            <el-row>
              <el-col :span="16">
                <div class="other-notice-title">{{ item.messageTitle }}</div>
              </el-col>
              <el-col :span="8">
                <div class="other-notice-date">{{ item.createDate }}</div>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :lg="12" :md="24" :xs="24" :sm="24" v-if="statVisible">
        <pay-type />
      </el-col>

      <el-col :lg="12" :md="24" :xs="24" :sm="24" v-if="statVisible">
        <shop-type />
      </el-col>

      <div v-if="false" style="position: fixed; bottom: 50px; left: 270px; z-index: 11">
        <div style="position: reactive; width: 266px; height: 166px">
          <a href="https://qa-loans-web.nsstest.com/" target="_blank">
            <img style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" src="~@/assets/toloans.png"
              alt="" />
          </a>
          <div style="width: 30px; height: 30px; position: absolute; top: 0; right: 0; z-index: 1111;cursor: pointer;"
            @click="
              () => {
                showToLoans = false;
              }
            "></div>
        </div>
      </div>
    </el-row>
  </div>
</template>

<script>
import G2Plot from "@/components/G2Plot.vue";
import Notification from "@/components/Notification";
import ShopType from "./ShopType";
import PayType from "./PayType";
import { chartsData } from "api/panel";
import { getSessionStorage } from "@/utils/storage";
import { includes } from "lodash";
import { moneyFormat, numFormat } from "utils";
import { messageList, getImportantMessage, getNormalMessage } from "api/message";

export default {
  components: {
    Notification,
    ShopType,
    PayType,
    G2Plot
  },
  data() {
    return {
      loading: true,
      messageLoading: true,
      showMessage: true,
      chartsCycle: "Today",
      showToLoans: true,
      cycleTypeList: [
        {
          value: "Today",
          name: this.$t("today")
        },
        {
          value: "Yestoday",
          name: this.$t("yestoday")
        },
        {
          value: "CurrentWeek",
          name: this.$t("currentWeek")
        },
        {
          value: "LastWeek",
          name: this.$t("lastWeek")
        },
        {
          value: "CurrentMonth",
          name: this.$t("currentMonth")
        },
        {
          value: "lastMonth",
          name: this.$t("lastMonth")
        },
        {
          value: "Quarter",
          name: this.$t("quarter")
        },
        {
          value: "Year",
          name: this.$t("year")
        }
      ],
      payDataList: [],
      shopDataList: [],
      options: {},
      chartOptions: {},
      messageData: [],

      messageType: "warning"
    };
  },
  computed: {
    statVisible() {
      // let sysModulePermisson = this.$store.state.app.userInfo.sysModulePermisson;
      // let permissionList = sysModulePermisson.map(item => item.modelName);

      // return includes(permissionList, "Stat");

      if (this.$store.state.app.userInfo.sysModulePermisson) {
        let sysModulePermisson = this.$store.state.app.userInfo.sysModulePermisson;
        let permissionList = sysModulePermisson.map(item => item.modelName);

        return includes(permissionList, "Stat");
      }
    }
  },
  mounted() {
    this.userInfo = JSON.parse(getSessionStorage("userInfo"));
    this.getChartsData();
    this.getTopNormalMessages();
    // this.getImportantMessages();
  },
  methods: {
    moneyFormat,
    numFormat,
    getTopNormalMessages() {
      this.messageLoading = true;
      getNormalMessage({ PageIndex: 1, PageSize: 6 }).then(response => {
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.messageData = response.result;
        }

        this.messageLoading = false;
      });
    },
    getChartsData(manually = false) {
      this.loading = true;
      chartsData({
        StatisticsCategory: this.chartsCycle
      }).then(response => {
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          let data = response.result.barData;

          data.forEach(item => {
            item.totalCount = parseFloat(item.totalCount);
            item.totalAmount = parseFloat(item.totalAmount);
          });

          this.chartOptions = {
            data: [data, data],
            xField: "groupKey",
            yField: ["totalAmount", "totalCount"],
            meta: {
              totalAmount: {
                alias: this.$t("money")
              },

              totalCount: {
                alias: this.$t("count")
              }
            },

            tooltip: {
              formatter: val => {
                let result = {};
                "totalCount" in val
                  ? (result = { name: this.$t("count"), value: numFormat(val.totalCount) })
                  : (result = { name: this.$t("money"), value: moneyFormat(val.totalAmount) });

                return result;
              }
            },

            yAxis: {
              totalAmount: {
                label: {
                  formatter: v => {
                    return `${moneyFormat(v)}`;
                  }
                },

                tickCount: 6
              },

              totalCount: {
                label: {
                  formatter: v => {
                    return `${numFormat(v)}${this.$t("countUnit")}`;
                  }
                },

                tickCount: 6
              }
            },
            geometryOptions: [
              {
                geometry: "line",
                smooth: true,
                color: "#532bd2"
              },
              {
                geometry: "column",
                color: "#48C9B0"
              }
            ]
          };

          this.loading = false;
        }

        if (this.$settings.gTag && manually) {
          console.log("gtag", "/api/trade/tradestatistics");
          gtag("event", "/api/trade/tradestatistics", {
            event_category: "API Call",
            ent_name: this.$store.state.app.userInfo.entName,
            ent_displayname: this.$store.state.app.userInfo.entDisplayName,
            user_id: this.$store.state.app.userInfo.userId,
            uuid: this.$store.state.app.userInfo.userId
          });
          // user_name: this.$store.state.app.userInfo.userName
          // user_email: this.$store.state.app.userInfo.userEmail,
        }
      });
    },
    goNoticeDetails(message) {
      console.log("message", message);
      // let index = message.messageType === 1 ? "important" : "other";
      this.$router.push({
        path: "message",
        query: { activeTypeId: message.messageType, currentMessageId: message.messageId }
      });
    },
    goLoans() {
      const param = {
        token: "",
        merchId: "232323232"
      };
      window.open(`http://localhost:3000?merchId=${param.merchId}`);
    }
  }
};
</script>

<style lang="scss">
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.panel-title {
  font-size: 26px;
  font-weight: 600;
}

.panel-card {
  margin-top: 20px;
  height: 305px;
  border-radius: 8px;

  .el-card__body {
    height: 100%;
    box-sizing: border-box;
  }
}

.other-notice-body {
  // height: 66px;
  border-bottom: 1px dotted #d8d8d8;
  cursor: pointer;
}

.other-notice-title {
  line-height: 36px;
  font-size: 16px;
  word-break: break-all;
}

.other-notice-date {
  color: #86858a;
  font-size: 12px;
  line-height: 16px;
}

.other-notice-body:last-of-type {
  border: 0;
}

.pay-logo img {
  width: 52px;
  padding: 3px 5px 3px;
  border: 1px solid #ccc;
}

.increase {
  color: #2aa76c;
}

.attenuate {
  color: #c50f3f;
}
</style>
